import React from 'react';
import '../css-templates/Main.css';
import '../css-templates/Home.css';
import { useMediaQuery } from "react-responsive";
import { DeviceSize } from '../../responsive';

function Home() {
    const isMobile = useMediaQuery({ maxWidth: DeviceSize.mobile });
    if (isMobile) {
        return (
            <center>
                <div className="container" style={{ textAlign: "center" }}>
                    <h1><small>WebDev International</small></h1>
                    <h2>Welcome to WebDev-International</h2>
                    <p>Specializing in exceptional digital experiences, WebDev International offers tailored web development services. From standout MERN stack websites to user-friendly WordPress setups and brand enhancement solutions, we deliver top-notch results.</p>

                    <h2>Why Choose WebDev-International</h2>
                    <p>Our experienced team provides cutting-edge solutions, prioritizing client satisfaction with on-time quality delivery. Contact us for a free consultation and elevate your business!</p>

                    <div style={{ border: "2px solid red", padding: "10px" }}>Construct a Professional Website for Your Business Today</div>
                    <div className="section">
                        <p>A great website is crucial for effective customer engagement. We guarantee user-friendly websites catering to various forms including company sites, blogs, personal portfolios, and e-commerce platforms.</p>
                        <center>
                            <div className='home-section-1' style={{ color: "white" }}>
                                <p>Company website</p>
                                <p>Blog</p>
                                <p>Personal Website</p>
                                <p>Portfolio</p>
                                <p>E-commerce website</p>
                            </div>
                        </center>
                    </div>
                </div>
            </center>


        )
    }
    return (
        <div style={{ textAlign: 'center' }}>
    <center>
        <div className="container">
            <h1><small>WebDev International</small></h1>
            <h2>Welcome to WebDev-International</h2>
            <p>Specializing in crafting exceptional digital experiences, WebDev International offers top-notch solutions for powerful websites using MERN Stack, effortless WordPress setups, brand visibility enhancement, and powerful branding.</p>

            <h2>Why Choose WebDev-International</h2>
            <p>With an experienced team providing cutting-edge solutions and a client-centric approach, we prioritize your satisfaction with on-time, quality delivery. Contact us for a free consultation to elevate your business!</p>

            <div style={{ border: "2px solid red" }}><h2>Construct a Professional Website for Your Business Today</h2></div>
            <div className="section">
                <p>Engage customers effectively with a professional website that reflects your brand. We guarantee user-friendly websites, designed for various forms like company sites, blogs, personal portfolios, and e-commerce platforms.</p>
                <div className='home-section-1' style={{ color: "white" }}>
                    <p>Company website</p>
                    <p>Blog</p>
                    <p>Personal Website</p>
                    <p>Portfolio</p>
                    <p>E-commerce site</p>
                </div>
            </div>
            {/* Contact Information */}
        </div>
    </center>
</div>

    );
}

export default Home;
