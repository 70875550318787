import React from 'react'
import Maintenance from '../../modules/js-templates/UnderConstruction';

function Services() {
  return (
    <div>
      <head>
        <meta charset="utf-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="description" content="" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />


      </head>
      <div>
        <center>
          {<Maintenance />}
        </center>
      </div>
    </div>

  )
}

export default Services;