import React from 'react'
import Nav from '../sections/Nav'
import Logo from '../sections/logo';

function Header() {
    return (
        <div>
            <div className='Logo'>{<Logo />}</div>
            <div className='Nav'>{<Nav />}</div>

        </div>
    )
}

export default Header;