import React from 'react';
// import Hats from '../../images/Hats.jpg';
import '../../modules/css-templates/Sides.css'


function SideLeft() {
    return (
        <div>
            {/* <img src={Hats} alt="Side Left" className='SideLeft' /> */}
        </div>
    )
}

export default SideLeft;