import React from 'react';
import UnderConstruction from '../../images/UnderConstruction.jpg';
import '../../modules/css-templates/Maintenance.css'

function Maintenance() {
    return (
        <div className="maintenance">
            <div>
                <h2>Under Construction</h2>
                <img src={UnderConstruction} alt="maintenance" className='Maintenance' />
            </div>
        </div>
    )
}

export default Maintenance;
