import './App.css';
import Header from './components/modules/sections/Header';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './components/modules/js-templates/Home';
import Services from './components/modules/js-templates/Services';
import Marketing from './components/modules/js-templates/Marketing';
import About from './components/modules/js-templates/About';
import SideLeft from './components/modules/sections/SideLeft';
import SideRight from './components/modules/sections/SideRight';
import Footer from './components/modules/sections/Footer';
import { Helmet } from 'react-helmet';

function App() {
  return (
    <>
      <BrowserRouter>
        <Helmet>
          <title>WebDev International</title>
          <meta name='keywords' content='Affordable websites, website, website development, developer owned' />
          <meta name='description' content='Your SEO description here' />
        </Helmet>

        <Header />
        <SideLeft />
        <SideRight />

        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/services' element={<Services />} />
          <Route path='/marketing' element={<Marketing />} />
          <Route path='/about' element={<About />} />
        </Routes>

        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
