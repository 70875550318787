import React from 'react'
import "../../modules/css-templates/logo.css";
// import c1 from "../../images/c1.png";
import cardBack from '../../images/cardBack.png';
import { useMediaQuery } from "react-responsive";
import { DeviceSize } from '../../responsive';

function Logo() {
    const isMobile = useMediaQuery({ maxWidth: DeviceSize.mobile });

    if (isMobile) {
        return (
            <div className='mimg-container'>
                <center>
                    <img src={cardBack} alt="company logo" />
                </center>
            </div>
        )
    }
    return (
        <div className='img-container'>
            <center>
                <img src={cardBack} alt="company logo" />
            </center>
        </div>
    )

    // return (
    //     <div className='img-container'>
    //         <center>
    //             <img src={c1} alt="company logo" />
    //         </center>
    //     </div>
    // )
}

export default Logo;