import React from 'react'
import "../../modules/css-templates/Marketing.css"
// import Maintenance from './UnderConstruction';
import Hats from '../../images/Hats.jpg'
import Shirts from '../../images/Shirts.jpg'
import { Helmet } from 'react-helmet'

function Marketing() {
  return (

    <div className='marketing'>
    <Helmet>
      <title>Marketing</title>
      <meta name='description' content='Affordable pricing'/>
    </Helmet>
      <center>
        <br />
        {/* {<Maintenance />} */}
        <div className='container'>

          <div>

            <h3 className='intro'>
              <b>
                FREE Consultation
                Nothing to BIG.
                <br />
                Nothing to small.
              </b>
            </h3>

            <br />
            <br />
            <div className='pack'>
              <div className='card1'>
                <h3>Hats</h3>
                <img src={Hats} alt='Hats' className='apparel' />
              </div>
              <div className='card2'>
                <h3>Shirts</h3>
                <img src={Shirts} alt='Shirts' className='apparel' />
              </div>
            </div>
            <div className='promotional'>
              <h3>Promotional material such as: </h3>
              <div>
                <p>promoting your business via social media</p>
                <p>adspace</p>
              </div>
              <h4>Contact</h4>
              <a href="mailto:john.cook@webdev-international.com">John </a>
              <h4>or you can</h4>
              <a href="mailto:marketing@webdev-international.com">Email us</a>
            </div>
          </div>
        </div>
      </center >
    </div>
  )
}

export default Marketing;