import React from 'react'
// import Hat from '../../images/Hats.jpg'
import '../../modules/css-templates/Sides.css'

function SideRight() {
    return (
        <div>
            {/* <img src={Hat} alt="Side Right" className='SideRight' /> */}
        </div>
    )
}

export default SideRight;