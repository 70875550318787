import React from 'react';
import { Link } from "react-router-dom";
import "../css-templates/nav.css";
import { useMediaQuery } from "react-responsive";
import { DeviceSize } from '../../responsive';


function Nav() {
    const isMobile = useMediaQuery({ maxWidth: DeviceSize.mobile });


    if (isMobile) {
        return (
            <div className='mNav'>
                <Link to="/">Home </Link>
                {/* <Link to='/services'>Services </Link> */}
                <Link to='/marketing'>Marketing </Link>
                <Link to='/about'>About </Link>
                {/* <Link to='/contact'>Contact</Link> */}
            </div>
        )
    }
    return (
        <div className='Nav'>
            <Link to="/">Home</Link>
            {/* <Link to='/services'>Services</Link> */}
            <Link to='/marketing'>Marketing</Link>
            <Link to='/about'>About</Link>
            {/* <Link to='/contact'>Contact</Link> */}
        </div>
    )

};

export default Nav;