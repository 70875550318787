import React from 'react';

export default function Footer() {
  return (
    <div className='footer'>
      <center>
        <p style={{
          backgroundColor: "rgba(204, 10, 10, 0.711)",
          display: "inline-block",
          padding: "5px 10px",
          fontSize: "3vh",
          color: "white",
        }}>
          Reach us or Follow us on {' '}
          <span style={{ backgroundColor: "inherit" }}>
            <a href="https://www.facebook.com/WebDevInternational" style={{ color: "white" }}>FaceBook</a>
          </span>{' '}
          for more information.
          {/* <h2>Contact Us:</h2> */}
                {/* <p>Phone: +1 (281) 939 4836</p> */}
                {/* <p>Email: info@webdev-international.com</p> */}
        </p>
        <p style={{
          backgroundColor: "rgba(197, 8, 8, 0.716)",
        //   padding: "1vw 1vw",
          fontSize: "3vh",
          width:"auto",
          color: "white",
        }}>
          Copyright &copy; 2022<br />
          WebDev International LLC
        </p>
      </center>
    </div>
  );
}
